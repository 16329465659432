import { Gender } from '../gqlEnums/gender'
import { WizardGender } from './enums/gender'

export const genderMap = {
  [WizardGender.Man]: Gender.Male,
  [WizardGender.Woman]: Gender.Female
}

export const genderTranslationMap = {
  [WizardGender.Man]: 'Мужчина',
  [WizardGender.Woman]: 'Женщина'
}
