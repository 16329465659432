import dayjs from 'dayjs'

export const formatDisplayNullableValue = (value, emptyText = '—') =>
  value ? value : emptyText

export const formatDisplayDateWithTime = (date) =>
  dayjs(date).format('DD-MM-YYYY HH:mm')

export const formatBoolToBoolWord = (bool) => (bool ? 'да' : 'нет')

export const trimmer = (str) => (str ?? '').replace(/\n|\r/g, ' ').trim()

const PENNIES_MULTIPLIER = 100
const ROUBLE = '₽'

const formatNumThousands = (num) =>
  new Intl.NumberFormat('ru-Ru').format(num).replace(' ', '\u00A0')

export const formatPriceRoublesWithPennies = ({
  amount,
  postfix,
  withPostfix = true
}) =>
  [
    formatNumThousands(amount / PENNIES_MULTIPLIER),
    ...(withPostfix ? [postfix ?? ROUBLE] : [])
  ].join(' ')

export const joinByDot = (...args) => args.join(' • ')
