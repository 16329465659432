import React from 'react'
import { VideoBadge } from './VideoBadge'

export const WithVideoBadge = ({ children, tooltip }) => (
  <>
    {children}
    {tooltip.visible && (
      <span>
        <VideoBadge size={tooltip.size} tooltipSize={tooltip.tooltipSize} />
      </span>
    )}
  </>
)
