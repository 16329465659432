import { HowFeeling } from '../gqlEnums/howFeeling'
import { WizardHowFeeling } from './enums/howFeeling'

export const howFeelingMap = {
  [WizardHowFeeling.Norm]: HowFeeling.Norm,
  [WizardHowFeeling.Good]: HowFeeling.Good,
  [WizardHowFeeling.Difficult]: HowFeeling.Difficult,
  [WizardHowFeeling.Terrible]: HowFeeling.Terrible
}

export const howFeelingTranslationMap = {
  [WizardHowFeeling.Good]: 'Хорошо',
  [WizardHowFeeling.Norm]: 'Нормально',
  [WizardHowFeeling.Difficult]: 'Сложно',
  [WizardHowFeeling.Terrible]: 'Ужасно'
}
