import dayjs from 'dayjs'
import { formatAgeTextByYears } from './pluralFormatters'

export const getYearsWithText = (birthdayDate) => {
  const age = birthdayDate
    ? dayjs().diff(dayjs(birthdayDate), 'years')
    : undefined

  if (age) {
    return [age, formatAgeTextByYears(age)].join(' ')
  }
}

export const getExperienceWithText = (startOfPractice) => {
  const workExperience = startOfPractice
    ? dayjs().diff(dayjs(startOfPractice.toString()), 'years') + 1
    : undefined

  if (workExperience) {
    return [workExperience, formatAgeTextByYears(workExperience)].join(' ')
  }
}
