import { Language } from '../gqlEnums/language'
import { WizardLanguage } from './enums/language'

export const languageMap = {
  [WizardLanguage.Russian]: Language.Russian,
  [WizardLanguage.English]: Language.English
}

export const languageTranslationMap = {
  [WizardLanguage.Russian]: 'Русский',
  [WizardLanguage.English]: 'Английский'
}
