import React from 'react'
import { BritishBadge } from './BritishBadge'
import { Tooltip } from '../../../../youtalk-storybook/src/ui'
import { isMobile } from '../../isMobile'

export const WithBritishBadge = ({ children, tooltip }) => (
  <>
    {children}
    {tooltip.visible && (
      <Tooltip
        content={
          <>
            Работает на русском и<br /> английском языке
          </>
        }
      >
        <span style={{ pointerEvents: isMobile ? 'none' : 'unset' }}>
          <BritishBadge size={tooltip.size} tooltipSize={tooltip.tooltipSize} />
        </span>
      </Tooltip>
    )}
  </>
)
