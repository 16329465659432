import { SessionType } from '../gqlEnums/sessionType'
import { WizardPrices } from './enums/prices'
import { genderMap } from './genderMap'
import { howFeelingMap } from './howFeelingMap'
import { languageMap } from './languageMap'
import { pricesMap } from './pricesMap'
import { therapyTypeCatalogFilterMap, therapyTypeMap } from './therapyTypeMap'

export const wizardValuesToValidSessionTypeEnum = ({ therapyType }) => {
  const format = therapyTypeCatalogFilterMap[therapyType]
  if (format === SessionType.Family) {
    return SessionType.Family
  }
  return SessionType.Individual
}

const parseAge = (age, byDefault) =>
  Number.isNaN(Number(age)) ? byDefault : Number(age)

const getPricesField = (prices) => {
  if (!prices || prices.length === 0) {
    return []
  }

  if (
    prices.every(
      (price) => Object.hasOwn(price, 'from') && Object.hasOwn(price, 'to')
    )
  ) {
    return prices
  }

  const keys = Object.values(WizardPrices)

  return prices
    .sort((a, b) => keys.indexOf(a) - keys.indexOf(b))
    .map((price) => pricesMap[price])
}
///convert to valid gql enums
export const formatCatalogData = (data) => {
  if (!data) {
    return {}
  }
  const [ageMin, ageMax] = data?.psychologistAge ?? [undefined, undefined]

  return {
    name: data.name,
    age: data.age,
    clientType: therapyTypeMap[data.client],
    clientFeelings: howFeelingMap[data.howFeeling],
    sessionFormat: 'VIDEO',
    workAreas: data.workAreas ?? [],
    workApproaches: data.approaches ?? [],
    language: languageMap[data.language],
    psychologistPreference: {
      ...(data.gender
        ? {
            gender: genderMap[data.gender]
          }
        : {}),
      ageMin: parseAge(ageMin, 24),
      ageMax: parseAge(ageMax, 65),
      lgbtq: data.friendliness ?? true
    },
    sessionDateTime: data.sessionDateTime,
    aboutHelp: data.aboutHelp ?? '',
    psychologistID: data.psychologistID,
    sourceId: data.sourceId,
    aboutPsychologist: `${process.env.GATSBY_SITEURL}/psychologist/${data.psychologistID}`,
    prices: getPricesField(data.prices)
  }
}
