export const getCheckDateInDaysRangeCb =
  (date) =>
  ({ startDate, finishDate }) => {
    if (startDate && finishDate) {
      return date.isBetween(startDate, finishDate, 'day', '[)')
    } else if (startDate) {
      return date.isSame(startDate, 'day') || date.isAfter(startDate)
    } else if (finishDate) {
      return date.isSame(finishDate, 'day') || date.isBefore(finishDate)
    }
    return false
  }
