import React from 'react'
import britishFlag from '../../../../static/img/icons/language/british-flag.png'
import styled, { css } from 'styled-components'
import { AvatarSizesEnum, avatarSizeMap } from '..'
import { color } from '../../../styles/vars/colors'

export const BritishBadge = styled(({ className }) => (
  <div className={className}>
    <img alt="british" src={britishFlag} />
  </div>
))`
  position: absolute;
  right: 0;
  bottom: 0;
  border: 1px solid ${color.border};
  border-radius: 50%;
  width: inherit;
  height: inherit;
  background: ${color.background};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    pointer-events: none;
  }

  ${({ size }) => {
    if (size === AvatarSizesEnum.Small || size === avatarSizeMap.small) {
      return css`
        width: 24px;
        height: 24px;

        img {
          width: 14px;
          height: 14px;
        }
      `
    }
    if (size === AvatarSizesEnum.Medium || size === avatarSizeMap.medium) {
      return css`
        width: 32px;
        height: 32px;

        img {
          width: 19px;
          height: 19px;
        }
      `
    }
    if (size === AvatarSizesEnum.Large || size === avatarSizeMap.large) {
      return css`
        width: 40px;
        height: 40px;

        img {
          width: 25px;
          height: 25px;
        }
      `
    }
  }}

  img {
    ${({ tooltipSize }) =>
      tooltipSize === 'small' &&
      css`
        width: 12px;
        height: 12px;
      `}
  }
`
