const pluralRulesRussain = new Intl.PluralRules('ru-RU')

const words = {
  sessions: new Map([
    ['one', 'сессия'],
    ['few', 'сессии'],
    ['many', 'сессий']
  ]),
  psychologists: new Map([
    ['one', 'Психолог'],
    ['few', 'Психолога'],
    ['many', 'Психологов']
  ]),
  years: new Map([
    ['one', 'год'],
    ['few', 'года'],
    ['many', 'лет']
  ]),
  experience: new Map([
    ['one', 'год'],
    ['few', 'лет'],
    ['many', 'лет']
  ]),
  people: new Map([
    ['one', 'человек'],
    ['few', 'человека'],
    ['many', 'человек']
  ]),
  rouble: new Map([
    ['one', 'рубль'],
    ['few', 'рубля'],
    ['many', 'рублей']
  ]),
  reviews: new Map([
    ['one', 'отзыв'],
    ['few', 'отзыва'],
    ['many', 'отзывов']
  ])
}
const getWord = (number, suffixType) => {
  const rule = pluralRulesRussain.select(number)

  return words[suffixType].get(rule)
}

export const formatSessionTextByAmount = (number) => getWord(number, 'sessions')
export const formatReviewsTextByCount = (number) => getWord(number, 'reviews')

export const formatPsychologistsTextByAmount = (number) =>
  getWord(number, 'psychologists')

export const formatAgeTextByYears = (number) => getWord(number, 'years')

export const formatExperienceTextByYears = (number) =>
  getWord(number, 'experience')

export const formatPeopleTextByAmount = (number) => getWord(number, 'people')

export const formatRoubleTextByAmount = (number) => getWord(number, 'rouble')
