import { track } from '@amplitude/analytics-browser'
import { useTrackEffect } from './utils'

export const trackVideoOpen = ({ source }) =>
  track({
    event_type: 'video_open',
    event_properties: {
      source: source
    }
  }).promise

export const useTrackVideoOpen = ({ source }) =>
  useTrackEffect(trackVideoOpen, { source })
