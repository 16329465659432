import { SessionType } from '../gqlEnums/sessionType'
import { TherapyType } from '../gqlEnums/therapyType'
import { WizardTherapyType } from './enums/therapyType'

export const therapyTypeMap = {
  [WizardTherapyType.Individual]: TherapyType.Individual,
  [WizardTherapyType.FamilyChild]: TherapyType.FamilyChild,
  [WizardTherapyType.FamilyPartner]: TherapyType.FamilyPartner
}

export const therapyTypeTranslationMap = {
  [WizardTherapyType.Individual]: 'Мне лично',
  [WizardTherapyType.FamilyChild]: 'Нам с ребёнком',
  [WizardTherapyType.FamilyPartner]: 'Нам с партнёром'
}

export const therapyTypeCatalogFilterMap = {
  [WizardTherapyType.Individual]: SessionType.Individual,
  [WizardTherapyType.FamilyChild]: SessionType.Family,
  [WizardTherapyType.FamilyPartner]: SessionType.Family
}
